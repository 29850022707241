import { Outlet, Navigate, useLocation } from "react-router-dom";
import React from "react";
import AuthToken from "../context/AuthToken";

const PrivateRoutes = () => {
  const auth = AuthToken();
  const location = useLocation();
  return auth ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PrivateRoutes;
