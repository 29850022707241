import { Fill, Stroke, Style } from "ol/style";
import selectedStyle from "../OLStyles/EditStyle";

const hoverOnParcel = (map, hvelem, id, parcelEdit) => {
  if (map) {
    map.getLayers().forEach((layer) => {
      if (layer.get("title") && layer.get("title") === "Parcel") {
        layer.getLayers().forEach((item) => {
          item.getSource().forEachFeature((feature) => {
            if (feature.getId() === Number(id)) {
              const hoverStyleOne = [
                new Style({
                  stroke: new Stroke({
                    color: "red",
                    width: 2,
                  }),
                  fill: new Fill({
                    color: "rgb(255, 255, 255, 0.8)",
                  }),
                }),
              ];
              const hoverStyleTwo = [
                new Style({
                  stroke: new Stroke({
                    color: "red",
                    width: 2,
                  }),
                  fill: new Fill({
                    color: "rgb(255, 255, 255, 0.2)",
                  }),
                }),
              ];
              if (hvelem === "mouseover")
                feature.setStyle(hoverStyleOne);
              else if (hvelem === "mouseout") {
                if (parcelEdit)
                  feature.setStyle(selectedStyle);
                else
                  feature.setStyle(hoverStyleTwo);
              }
            }
          });
        });
      }
    });
  }
};

export default hoverOnParcel;
