import React, { useContext, useState, createContext } from "react";

const OTPContext = createContext();

export function OTPContextProvider({ children }) {
  const [otpContainer, setOtpContainer] = useState(true);

  return (
    <OTPContext.Provider
      value={{
        otpContainer,
        setOtpContainer,
      }}
    >
      {children}
    </OTPContext.Provider>
  );
}

export function useOTPContext() {
  const context = useContext(OTPContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
