import { transformExtent } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

const setBackblazeImage = (map, date, item, tileGridLyr, status) => {
  const bucketUrl = "https://krypton-mapnexa.s3.us-east-005.backblazeb2.com";
  
  if (map && date) {
    console.log(`Setting Backblaze image for date: ${date}`);
    var tileGridImage;
    var projection = transformExtent(item, "EPSG:4326", "EPSG:3857");

    const pathSegments = window.location.pathname.split("/"); // Split the URL path
    const order_id = pathSegments[pathSegments.indexOf("order") + 1]; // Extract order_id dynamically
    
    let url;
    if (status === "measurement_completed") {
      url = `${bucketUrl}/${order_id}/{z}/{x}/{y}.jpeg`;
    } else {
      url = `${bucketUrl}/${order_id}/{z}/{x}/{y}.jpeg`;
    }
    
    tileGridImage = new TileLayer({
      title: "Tile Imagery",
      extent: projection,
      source: new XYZ({
        url: url,
        tileLoadFunction: function (imageTile, src) {
          imageTile.getImage().src = src;
          console.log(`Loading tile: ${src}`);
          imageTile.getImage().onerror = function () {
            console.error(`Failed to load tile: ${src}`);
          };
        },
        minZoom: 16, // Add minZoom property
        maxZoom: 21, // Add maxZoom property
      }),
      crossOrigin: "Anonymous",
    });

    tileGridLyr.current.getLayers().clear();
    tileGridLyr.current.getLayers().push(tileGridImage);

    map.getLayers().forEach(function (layer) {
      if (layer && layer.get("title") === "Tile Imagery") {
        map.removeLayer(layer);
      }
    });
    map.addLayer(tileGridLyr.current);
    console.log("Backblaze layer added to map");
  } else {
    console.error("Map or date not available for setting Backblaze image");
  }
};

export default setBackblazeImage;
