import { Fill, Stroke, Style } from "ol/style";

const parcelStyle = [
  new Style({
    stroke: new Stroke({
      color: "red",
      width: 2,
    }),
    fill: new Fill({
      color: "rgb(255, 255, 255,0.2)",
    }),
  }),
];

export default parcelStyle;
