import * as turf from "@turf/turf";
import { toLonLat } from "ol/proj";
import { CONVERT } from "../../utils/constants";

const calculateArea = (parcelData) => {
  var polygonArea;
  var holesArea = 0;
  for (let i = 0; i < parcelData.length; i++) {
    const singlePolygon = parcelData[i];
    var singlePolygonCoords = [];
    if (i === 0) {
      for (let j = 0; j < singlePolygon.length; j++) {
        let lonLat = toLonLat(singlePolygon[j]);
        singlePolygonCoords.push(lonLat);
      }
      const geo = { type: "Polygon", coordinates: [singlePolygonCoords] };
      polygonArea = Number(
        parseFloat(turf.area(geo) / CONVERT.sqm_acre).toFixed(2)
      );
    } else if (i > 0) {
      for (let j = 0; j < singlePolygon.length; j++) {
        let lonLat = toLonLat(singlePolygon[j]);
        singlePolygonCoords.push(lonLat);
      }
      const geo = { type: "Polygon", coordinates: [singlePolygonCoords] };
      holesArea = Number(
        parseFloat(turf.area(geo) / CONVERT.sqm_acre).toFixed(2)
      );
    }
    polygonArea = polygonArea - holesArea;
  }
  return polygonArea.toFixed(2);
};

export default calculateArea;
