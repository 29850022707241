import React, { useState, useEffect, useContext } from "react";
import classes from "./SubmitOrder.module.css";
import { Button } from "@mui/material";
import { toLonLat } from "ol/proj";
import toast from "react-hot-toast";
import SubmitLoader from "../../assets/loader.gif";
import InfoIcon from "@mui/icons-material/Info";

function SubmitOrder({
  parcelArea,
  featureList,
  featureSelected,
  polygonList,
  id,
  orderStatus,
  setOrderStatus,
  nearmapImageDate,
  setOrderConfirm,
  commonAreaPlace,
  curbStripPlace,
  frontYardPlace,
}) {
  const [cost, setCost] = useState(0.0);
  const [deliveryTime, setDeliveryTime] = useState(0.0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [apiCost, setApiCost] = useState(null); // For storing the fetched cost

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const coordinatesToLatLong = () => {
    let parcelData = [];
    for (let i = 0; i < polygonList.length; i++) {
      const coordinate = [];
      let coords = polygonList[i].data;
      for (let j = 0; j < coords.length; j++) {
        const singlePolygon = coords[j];
        const singlePolygonData = [];
        for (let k = 0; k < singlePolygon.length; k++) {
          let lonLat = toLonLat(singlePolygon[k]);
          singlePolygonData.push(lonLat);
        }
        coordinate.push(singlePolygonData);
      }
      parcelData.push(coordinate);
    }
    return { geometry: { type: "MultiPolygon", coordinates: parcelData } };
  };

  const fetchOrderCost = async (orderId) => {
    try {
      const response = await fetch(
        `https://app.mapnexa.com/krypton/v1/api/order/prop-id/${orderId}`
      );
      const data = await response.json();

      if (data.success && data.data.status === "in_progress") {
        setApiCost(data.data.property_cost); // Set the fetched cost
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    // Fetch the order cost when the component mounts or the order status changes
    if (orderStatus === "in_progress" && id) {
      fetchOrderCost(id);
    }

    let totalFeatures = featureList.length;
    let propertyCost = 0;
    let basePrice = 2;

    // Calculate the total cost based on the parcel area and feature count
    if (parcelArea <= 10) {
      if (totalFeatures === 1) {
        propertyCost = Math.ceil(parcelArea) * 0.5;
      } else if (totalFeatures <= 5) {
        propertyCost = Math.ceil(parcelArea) * 1;
      } else if (totalFeatures <= 10) {
        propertyCost = Math.ceil(parcelArea) * 1.5;
      } else if (totalFeatures <= 15) {
        propertyCost = Math.ceil(parcelArea) * 2;
      } else if (totalFeatures <= 20) {
        propertyCost = Math.ceil(parcelArea) * 2.5;
      } else if (totalFeatures <= 25) {
        propertyCost = Math.ceil(parcelArea) * 3;
      } else if (totalFeatures <= 30) {
        propertyCost = Math.ceil(parcelArea) * 3.5;
      } else if (totalFeatures <= 35) {
        propertyCost = Math.ceil(parcelArea) * 4;
      } else if (totalFeatures <= 40) {
        propertyCost = Math.ceil(parcelArea) * 5;
      } else if (totalFeatures <= 50) {
        propertyCost = Math.ceil(parcelArea) * 5.5;
      }
    } else {
      if (totalFeatures === 1) {
        propertyCost = Math.ceil(parcelArea) * 0.45;
      } else if (totalFeatures <= 5) {
        propertyCost = Math.ceil(parcelArea) * 0.9;
      } else if (totalFeatures <= 10) {
        propertyCost = Math.ceil(parcelArea) * 1.35;
      } else if (totalFeatures <= 15) {
        propertyCost = Math.ceil(parcelArea) * 1.8;
      } else if (totalFeatures <= 20) {
        propertyCost = Math.ceil(parcelArea) * 2.25;
      } else if (totalFeatures <= 25) {
        propertyCost = Math.ceil(parcelArea) * 2.7;
      } else if (totalFeatures <= 30) {
        propertyCost = Math.ceil(parcelArea) * 3.15;
      } else if (totalFeatures <= 35) {
        propertyCost = Math.ceil(parcelArea) * 3.6;
      } else if (totalFeatures <= 40) {
        propertyCost = Math.ceil(parcelArea) * 4.05;
      } else if (totalFeatures <= 45) {
        propertyCost = Math.ceil(parcelArea) * 4.95;
      }
    }

    // Add the imagery cost if imagery is not SATELLITE
    if (nearmapImageDate !== "satellite") {
      if (parcelArea <= 10) {
        propertyCost += Math.ceil(parcelArea) * 0.4;
      } else {
        propertyCost += Math.ceil(parcelArea) * 0.3;
      }
    }

    // Add the base price of $2
    propertyCost += basePrice;

    // Apply common area discount if applicable
    if (commonAreaPlace) {
      propertyCost = parseFloat(propertyCost) * 0.8;
    }

    setCost(propertyCost.toFixed(2));

    // Calculate estimated processing time
    let propertyTime = 0;
    if (parcelArea <= 1) {
      propertyTime = 5;
    } else if (parcelArea <= 2) {
      propertyTime = 6;
    } else if (parcelArea <= 3) {
      propertyTime = 7;
    } else if (parcelArea <= 4) {
      propertyTime = 8;
    } else if (parcelArea <= 5) {
      propertyTime = 9;
    } else if (parcelArea <= 10) {
      propertyTime = 12;
    } else if (parcelArea <= 15) {
      propertyTime = 14;
    } else if (parcelArea <= 20) {
      propertyTime = 16;
    } else if (parcelArea <= 30) {
      propertyTime = 20;
    } else if (parcelArea <= 50) {
      propertyTime = 24;
    } else if (parcelArea <= 100) {
      propertyTime = 35;
    } else if (parcelArea <= 150) {
      propertyTime = 50;
    } else {
      propertyTime = 72;
    }

    setDeliveryTime(propertyTime);
  }, [
    parcelArea,
    featureList,
    nearmapImageDate,
    commonAreaPlace,
    orderStatus,
    id,
  ]);

  const handleOrderSubmission = () => {
    if (parcelArea <= 0) {
      toast.error("Please draw a parcel to place your order");
      return false;
    }
    document.body.classList.remove("cut-cursor");
    document.body.classList.remove("delete-cursor");
    setOrderConfirm(true);
    const latLng = coordinatesToLatLong();
    const idList = [];

    // Continue with order submission logic...
  };

  return (
    <div className={classes.SubmitOrderContainer}>
      <div className={classes.AreaContainer}>
        Local Area :{" "}
        <span className={classes.AnswerContainer}>{parcelArea} acres</span>
      </div>
      <div className={classes.PriceContainer}>
        Price :{" "}
        <span className={classes.AnswerContainer}>
          USD $
          {orderStatus === "in_progress"  && apiCost !== null ? apiCost : cost}
        </span>
      </div>
      <div className={classes.ETAContainer}>
        Estimated Processing Time :{" "}
        <span className={classes.AnswerContainer}>{deliveryTime} hours</span>
      </div>
      <div className={classes.PlaceOrder}>
        {orderStatus === "draft" ? (
          <Button
            variant="contained"
            className={classes.PlaceOrderBtn}
            onClick={handleOrderSubmission}
          >
            Place Order
          </Button>
        ) : (
          <img
            src={SubmitLoader}
            alt="Order loader"
            className={classes.SubmitLoader}
          />
        )}

        <div
          style={{
            position: "relative",
            display: "inline-block",
            marginLeft: "2%",
          }}
        >
          <div
            onMouseEnter={toggleTooltip}
            onMouseLeave={toggleTooltip}
            style={{ cursor: "pointer", marginLeft: "8px" }}
          >
            <InfoIcon />
          </div>
          {showTooltip && (
            <div className={classes.TooltipContainer}>
              <h4>Pricing</h4>
              <p>
                For properties up to 10 acres, pricing varies based on the
                number of features.
              </p>
              <p>
                For properties above 10 acres, pricing is slightly reduced per
                feature.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubmitOrder;
