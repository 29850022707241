import { Outlet, Navigate } from "react-router-dom";
import React from "react";
import AuthToken from "../context/AuthToken";

const SignedInRouter = () => {
  const auth = AuthToken();
  return auth ? <Navigate to="/home" /> : <Outlet />;
};

export default SignedInRouter;
