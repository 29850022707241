import { transformExtent } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

const setNearmap = (map, date, nearmapcoords, tileGridLyr, token) => {
  // Sanitize or validate the date to avoid any unwanted strings like "satellite" or empty values
  const sanitizedDate = typeof date === "string" && date.match(/^\d{4}-\d{2}-\d{2}$/)
    ? date
    : "";

  if (map && sanitizedDate && nearmapcoords.length && token) {
    nearmapcoords.forEach((item) => {
      var projection = transformExtent(item, "EPSG:4326", "EPSG:3857");

      var tileGridImage = new TileLayer({
        extent: projection,
        source: new XYZ({
          url: `https://app.mapnexa.com/krypton/tiles/v3/Vert/{z}/{x}/{y}.jpg?until=${sanitizedDate}`,
          tileLoadFunction: (tile, src) => {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", src, true);
            xhr.setRequestHeader("Authorization", `Token ${token}`);  // Include token in headers
            xhr.responseType = "blob";

            xhr.onload = function () {
              if (xhr.status === 200) {
                const objectUrl = URL.createObjectURL(xhr.response);
                tile.getImage().src = objectUrl;
              } else {
                console.error(`Failed to load tile: ${src}, status: ${xhr.status}`);
              }
            };

            xhr.onerror = function () {
              console.error(`Error loading tile: ${src}`);
            };

            xhr.send();
          }
        }),
        crossOrigin: "Anonymous",
      });

      tileGridLyr.current.getLayers().array_.push(tileGridImage);

      map.getLayers().forEach(function (layer) {
        if (layer && layer.get("title") && layer.get("title") === "Tile Imagery") {
          map.removeLayer(layer);
        }
      });

      map.addLayer(tileGridLyr.current);
    });
  }
};

export default setNearmap;
