import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import selectedStyle from "../OLStyles/EditStyle";

const hoverOnOrderFeature = (
  map,
  hex2rgba,
  hvelem,
  id,
  setHoverData,
  showPopup,
  editInteraction
) => {
  if (map) {
    map.getLayers().forEach((layer) => {
      if (layer.get("title") && layer.get("title") === "Order") {
        layer.getLayers().forEach((item) => {
          item.getSource().forEachFeature((feature) => {
            if (
              feature.get("name") === id[1] &&
              feature.get("hover_id") === Number(id[0]) &&
              id[3]
            ) {
              let color;
              if (hvelem === "mouseover") {
                color = hex2rgba(id[2], 0.6);
                var hoverStyleOne = new Style({
                  fill: new Fill({
                    color: color,
                  }),
                  stroke: new Stroke({
                    color: hex2rgba(id[2], 0.8).toString(),
                    width: 5,
                  }),
                  image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                      color: "#F49D1A",
                    }),
                  }),
                });
                setHoverData({
                  id: feature.get("hover_id"),
                  name: feature.get("name"),
                  measurement: feature.get("measurement"),
                  measurement_type: feature.get("measurement_type"),
                });
                showPopup(true);
                feature.setStyle(hoverStyleOne);
              } else if (hvelem === "mouseout") {
                color = hex2rgba(id[2], 0.3);
                const color2 = hex2rgba(id[2], 1);
                var hoverStyleTwo = new Style({
                  fill: new Fill({
                    color: color,
                  }),
                  stroke: new Stroke({
                    color: hex2rgba(id[2], 0.8).toString(),
                    width: 3,
                  }),
                  image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                      color: color2,
                    }),
                  }),
                });
                showPopup(false);
                if (editInteraction) {
                  feature.setStyle(selectedStyle);
                } else {
                  feature.setStyle(hoverStyleTwo);
                }
              }
            }
          });
        });
      }
    });
  }
};

export default hoverOnOrderFeature;
