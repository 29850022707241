import { useLayoutEffect, useState } from 'react';

const useScreenType = () => {
  const [isMobile, setIsMobile] = useState(false);
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 820); // Adjust the breakpoint as per your requirement
    };
    // Initial check on mount
    handleResize();
    // Add event listener for resize
    window.addEventListener('resize', handleResize);
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return isMobile;
};

export { useScreenType };
