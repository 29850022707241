const handleOrderVisiblity = (map, uploadedLayer, orderActive, setUploadedLayer) => {
    if (map) {
        map.getLayers().forEach((layer) => {
            if (layer && layer.get("title") && layer.get("title") === "Order") {
                // map.getLayers().pop(layer);
                map.removeLayer(layer);
            }
        });
        let updatedItemList = uploadedLayer.map((item) => {
            item.visible = orderActive;
            item.data.map((singleItem) => {
                singleItem.visible = orderActive;
                return { ...singleItem };
            });
            return { ...item };
        });
        setUploadedLayer([...updatedItemList]);
    }
};

export default handleOrderVisiblity;