import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AuthToken from "../../context/AuthToken";
import { SUBMIT_ORDER } from "../../helper/ApiUrl";
import "./UserPrompt.css";
import { TOAST_TYPE } from "../../utils/constants";

export default function UserPrompt({ togglePromptUser, handleError, handleSuccess }) {

    const params = useParams();

    const submitOrder = () => {
        axios({
            url: `${SUBMIT_ORDER}${params.id}/confirm`,
            method: "POST",
            headers: {
                Authorization: `Token ${AuthToken()}`,
            },
        })
            .then((res) => {
                if ("data" in res) {
                    togglePromptUser(false);
                    handleSuccess(TOAST_TYPE.SUCCESS, "Order submitted successfully");
                }
            })
            .catch((err) => {
                handleError(err);
            });
    }

    return (
        <>
            <div className="user-prompt-backdrop"></div>
            <div className="user-prompt-main-cont">
                <div className="user-prompt-inner-cont">
                    <div>Submitting the order will prompt user with order completion mail.</div>
                    <div>Do you want to continue?</div>
                    <div className="user-prompt-button-container">
                        <button onClick={submitOrder}>Submit</button>
                        <button onClick={() => togglePromptUser(false)}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
}