import { useContext } from "react";

import { useUserContext } from "../../context/UserContext";

import axios from "axios";
import LayerGroup from "ol/layer/Group";
import "./OpenLayersIdPage.css";

import { Modify, Select } from "ol/interaction";
import AuthToken from "../../context/AuthToken";
import { altShiftKeysOnly } from "ol/events/condition";
import { useLayerContext } from "../../context/LayerContext";
import { useLoader } from "../../context/LoaderProvider";
import { STATUS } from "../../utils/constants";
import { Fill, Stroke, Style } from "ol/style";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import handleLayerTypeMap from "../../OpenLayers/OLControls/MapViewControl";
import handleLayerTypeSatellite from "../../OpenLayers/OLControls/SatelliteViewControl";
import handleZoomIn from "../../OpenLayers/OLControls/ZoomInControl";
import handleZoomOut from "../../OpenLayers/OLControls/ZoomOutControl";
import calculateArea from "../../OpenLayers/OLFeatures/CalculateArea";
import getNearmapImage from "../../OpenLayers/OLFeatures/GetNearmapImage";
import getUsersDetails from "../../OpenLayers/OLFeatures/GetUsersDetails";
import handleOrderVisiblity from "../../OpenLayers/OLFeatures/HandleOrderVisibility";
import hoverOnOrderFeature from "../../OpenLayers/OLFeatures/HoverOnOrderFeature";
import hoverOnParcel from "../../OpenLayers/OLFeatures/HoverOnParcel";
import initializeMap from "../../OpenLayers/OLFeatures/InitializeMap";
import initializeMarker from "../../OpenLayers/OLFeatures/InitializeMarker";
import latLongToCoordinates from "../../OpenLayers/OLFeatures/LatlatLongToCoord";
import removeAllInteraction from "../../OpenLayers/OLFeatures/RemoveInteraction";
import renderParcel from "../../OpenLayers/OLFeatures/RenderParcel";

import setBackblazeImage from "../../OpenLayers/OLFeatures/SetBackblazeImage";
import selectedStyle from "../../OpenLayers/OLStyles/EditStyle";
import ImageryDropdown from "../../components/ImageryDropdown/ImageryDropdown";
import { ORDER_URL, REMOVE_HOLES_URL } from "../../helper/ApiUrl";
import { apiTokenQueries, API_URL } from "../../utils/ApiCall";
import Toaster from "../../components/Toaster/Toaster";
import {
  SUCCESS_STATUS_CODE,
  FAILURE_STATUS_CODE,
} from "../../utils/constants";
import { TOAST_TYPE } from "../../utils/constants";
import drawPolygon from "../../OpenLayers/OLInteractions/DrawInteraction";
import cutPolygon from "../../OpenLayers/OLInteractions/CutInteraction";
import LotIcon from "../../assets/lot_icon.svg";
import DrawPolygon from "../../assets/draw-icon.svg";
import EditPolygon from "../../assets/edit_icon.svg";
import CutPolygon from "../../assets/cut-hole.svg";
import DeletPolygon from "../../assets/delete-parcel.svg";
import DropDown from "../../components/DropDown/DropDown";
import CustomizeFeature from "../../components/CustomizeFeature/CustomizeFeature";
import MultiCheckbox from "../../components/MultiCheckBox/MultiCheckBox";
import SubmitOrder from "../../components/SubmitOrder/SubmitOrder";
import GeoJSONAccordion from "../../components/GeoJsonAccordian/Accordian";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import getLayersData from "../../OpenLayers/OLFeatures/GetLayersData";
import getLayerData from "../../OpenLayers/OLFeatures/GetLayerData";
import renderOrderLayer from "../../OpenLayers/OLFeatures/RenderOrderLayer";
import setCoordsToImage from "../../OpenLayers/OLFeatures/SetCoordsToImage";
import Hourglass from "../../assets/loader_hourglass.gif";
import TextField from "@mui/material/TextField";
import GetAuthToken from "../../utils/GetToken";
import { toLonLat } from "ol/proj";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import getHdImage from "../../OpenLayers/OLFeatures/GetHDImage";
import setHDImage from "../../OpenLayers/OLFeatures/SetHDImageMap";

import CloseIcon from '@mui/icons-material/Close';

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import DataObjectIcon from '@mui/icons-material/DataObject';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useOutletContext } from 'react-router-dom';
import setNearmap from "../../OpenLayers/OLFeatures/SetNearmap";

const OpenLayersIdPage = ({ handleError, handleSuccess }) => {
  const { setShowGeneratingReport } = useOutletContext();
  const token = AuthToken();
  const { userInfo } = useUserContext();
  const orderRef = useRef();
  const parcelRef = useRef();
  const markerRef = useRef();
  const { id } = useParams();
  const mapElement = useRef();
  const contentRef = useRef();
  const tileGridLyr = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [map, setMap] = useState();
  const nearmapImageRef = useRef();

  const completedGridLyr = useRef();
  const search = useLocation().search;
  const [status, setStatus] = useState(null);
  const [popup, showPopup] = useState(false);
  const [address, setAddress] = useState(null);
  const [imageDate, setImageDate] = useState();
  const [center, setCenter] = useState([0, 0]);
  const [hoverElem, setHoverElem] = useState();
  const { startLoader, stopLoader } = useLoader();
  const [hoverMapKey, setHoverMapKey] = useState();
  const [zoomLayer, setZoomLayer] = useState(true);
  const [coordinates, setCoordinates] = useState([]);
  const [polygonList, setPolygonList] = useState([]);
  const [parcelEdit, setParcelEdit] = useState(false);
  const [orderActive, setOrderActive] = useState(true);
  const [usersDetails, setUsersDetails] = useState([]);
  const [hoverParcelId, setHoverParcelId] = useState();
  const [uploadedLayer, setUploadedLayer] = useState([]);
  const [showDone, setShowDone] = useState(false);
  const [nearmapcoords, setNearmapcoords] = useState([]);
  const [featureGroup, setFeatureGroup] = useState([]); //Dropdown for feature group

  const [featureList, setFeatureList] = useState([]);
  const [featureSelected, setFeatureSelected] = useState(); //selected feature shows up here
  const [featureCount, setFeatureCount] = useState(0.0);
  const [totalFeatureList, setTotalFeatureList] = useState([]); //Used to show the total lists in popup

  const [nearmapImageDate, setNearmapImageDate] = useState("");
  const [nearmapSurveyData, setNearmapSurveyData] = useState([]);
  const [showNearmapImage, toggleNearmapImage] = useState(false);
  const [hoverParcelElement, setHoverParcelElement] = useState();
  const [showExportOptions, setShowExportOptions] = useState(false);

  const [hoverOrderFeatureId, setHoverOrderFeatureId] = useState();

  const [parcelArea, setParcelArea] = useState(0.0);
  const [selectedHoverFeature, setSelectedHoverFeature] = useState();

  const [hoverOrderFeatureElement, setHoverOrderFeatureElement] = useState();
  const [mappedFeatureList, setMappedFeatureList] = useState([]);
  const [viewId, setViewId] = useState(null);
  const [layersData, setLayersData] = useState([]);
  const [showInprogress, setshowInprogress] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [layersCount, setLayersCount] = useState();

  const [orderConfirm, setOrderConfirm] = useState(false);
  const [orderNotes, setOrderNotes] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showToggleOn, setShowToggleOn] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const [commonAreaPlace, setcommonAreaPlace] = useState(false);
  const [curbStripPlace, setcurbStripPlace] = useState(false);
  const [frontYardPlace, setfrontYardPlace] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageBound, setImageBound] = useState(null);
  const fullName = userInfo ? `${userInfo.name} ` : "";
  const email = userInfo ? userInfo.email : "";
  const contactNumber = userInfo ? userInfo.contact_number : "";

  const { setOlMap, editInteraction, multipleSelectInteraction } =
    useLayerContext();

  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  parcelRef.current = new LayerGroup({
    title: "Parcel",
    layers: [],
  });

  orderRef.current = new LayerGroup({
    title: "Order",
    layers: [],
  });

  markerRef.current = new LayerGroup({
    title: "Marker",
    layers: [],
  });

  nearmapImageRef.current = new LayerGroup({
    title: "NearmapImage",
    layers: [],
  });

  tileGridLyr.current = new LayerGroup({
    title: "Tile Imagery",
    layers: [],
  });

  completedGridLyr.current = new LayerGroup({
    title: "Completed Grid Layer",
    layers: [],
  });

  const [hoverData, setHoverData] = useState({
    id: null,
    name: null,
    measurement: null,
    measurement_type: null,
  });

  const [parcelStyle, setParcelStyle] = useState([
    new Style({
      stroke: new Stroke({
        color: "red",
        width: 2,
      }),
      fill: new Fill({
        color: "rgb(255, 255, 255,0.2)",
      }),
    }),
  ]);



  useLayoutEffect(() => {
    startLoader();
  }, []);

  const select = new Select({
    wrapX: false,
    style: selectedStyle,
    condition: altShiftKeysOnly,
  });

  const modify = new Modify({
    features: select.getFeatures(),
  });

  const selectToDelete = new Select({
    wrapX: false,
    style: selectedStyle,
  });

  const addEditInteraction = () => {
    document.getElementsByClassName("edit-parcel-buttons")[0].click();
  };
  const addDeleteInteraction = () => {
    document.getElementsByClassName("delete-parcel-buttons")[0].click();
  };
  const toggleShowBtn = () => {
    setShowToggleOn(!showToggleOn);
  };
  const deletePolygon = () => {
    document.body.classList.remove("cut-cursor");
    document.body.classList.add("delete-cursor");
    setParcelStyle([
      new Style({
        stroke: new Stroke({
          color: "red",
          width: 2,
        }),
        fill: new Fill({
          color: "rgb(255, 255, 255,0.2)",
        }),
      }),
    ]);
    setShowDone(true);
    setZoomLayer(false);
    removeAllInteraction(map);
    map.addInteraction(selectToDelete);
    // map.addInteraction(modify);

    selectToDelete.on("select", (event) => {
      if (event.selected.length > 0) {
        // At least one feature is selected
        const selectedFeature = event.selected[0]; // Assuming you want to delete the first selected feature
        const updatedList = polygonList.filter(
          (item) => item.id !== selectedFeature.id_
        );
        setPolygonList(updatedList);
        removeAllInteraction(map);
        addDeleteInteraction();
      }
    });
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
    // Additional logic to delete the file from the server or perform necessary actions...
  };

  useEffect(() => {
    if (
      (map && status === STATUS.MEASUREMENT_COMPLETED) ||
      status === STATUS.IN_PROGRESS
    )
      getHdImage(id, setImageUrl, setImageBound);
  }, [map, status]);

  useEffect(() => {
    if (map && imageUrl && imageBound)
      setHDImage(map, imageUrl, imageBound, nearmapImageRef);
  }, [imageUrl, imageBound]);

  const getFileTypeIcon = (fileType) => {
    // Logic to get file type icon or thumbnail based on the file type (e.g., pdf, image, etc.)
    // You can use external libraries or create your own logic to handle different file types
    // For simplicity, let's assume we're displaying generic icons for different file types.
    if (fileType.includes("pdf")) {
      return <i className="far fa-file-pdf"></i>; // Example: Font Awesome PDF icon
    } else if (fileType.includes("image")) {
      return <i className="far fa-file-image"></i>; // Example: Font Awesome Image icon
    } else {
      return <i className="far fa-file-alt"></i>; // Example: Font Awesome Generic File icon
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploading(true);
    setUploadedFile(file);

    // If you want to upload the file to an API immediately upon selection:
    // Replace the following lines with your API upload logic
    const formData = new FormData();
    formData.append("file", file);

    const accessToken = GetAuthToken(); // Replace 'YOUR_ACCESS_TOKEN' with your actual access token

    // Example API call using fetch with Authorization header
    fetch(`${API_URL}org/file`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle response from API
        setUploadedFileUrl(data.url);
        setUploading(false);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const editPolygon = () => {
    setShowDone(true);
    setZoomLayer(false);
    setParcelEdit(true);
    removeAllInteraction(map);
    map.addInteraction(select);
    map.addInteraction(modify);
    document.body.classList.remove("cut-cursor");
    document.body.classList.remove("delete-cursor");

    map.getLayers().forEach((layer) => {
      if (layer.get("title") && layer.get("title") === "Parcel") {
        var childLayers = layer.getLayers().getArray();
        console.log(childLayers);
        var length = childLayers.length;
        for (var i = 0; i < length; i++) {
          var lay = childLayers[i];
          lay.getSource().forEachFeature(function (feature) {
            select.getFeatures().push(feature);
          });
        }
      }
    });

    modify.on("modifyend", function (e) {
      const id = e.features.getArray()[0].getId();
      var coords = e.features.getArray()[0].getGeometry().getCoordinates();

      if (coords.length > 1) {
        const data = { parcel: coords };
        axios({
          url: `${REMOVE_HOLES_URL}`,
          method: "post",
          timeout: 8000,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((res) => {
            let updatedPolygon = polygonList.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  data: res.data.data,
                  measurement: calculateArea(coords),
                };
              }
              return item;
            });
            setPolygonList(updatedPolygon);
            setParcelStyle(selectedStyle);
            removeAllInteraction(map);
            addEditInteraction();
          })
          .catch((err) => console.error(err));
      }

      let updatedPolygon = polygonList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            data: coords,
            measurement: calculateArea(coords),
          };
        }
        return item;
      });
      setPolygonList(updatedPolygon);
      setParcelStyle(selectedStyle);
      removeAllInteraction(map);
      addEditInteraction();
    });
  };

  useEffect(() => {
    if (!nearmapImageDate) {
      if (status === STATUS.DRAFT) {
        if (nearmapcoords.length && nearmapcoords[0] != Infinity) {
          getNearmapImage(id, token, nearmapcoords, setNearmapSurveyData);
        }
      }
    }
  }, [nearmapcoords, status]);

  const getParcelData = () => {
    apiTokenQueries("GET", `order/prop-id/${id}`).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        const orderData = res.data.data;
        console.log(orderData);
        setAddress(orderData.address);
        setStatus(orderData.status);
        if (orderData.status === "in_progress") {
          setshowInprogress(true);
        }
        setImageDate(orderData.imagery_date);
        setNearmapImageDate(orderData.imagery_date);
        setCenter([orderData.long, orderData.lat]);
        if ("geometry" in orderData.parcel_data) {
          setCoordinates(
            orderData.parcel_data.geometry.coordinates,
            setPolygonList
          );
        }
        stopLoader();
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        handleSuccess(TOAST_TYPE.ERROR, res.data.errorDesc);
      }
    });
  };

  useEffect(() => {
    initializeMap(mapElement, setMap);
    getParcelData();
  }, []);

  useEffect(() => {
    if (map) {
      renderParcel(
        polygonList,
        map,
        parcelRef,
        parcelStyle,
        zoomLayer,
        status,
        nearmapcoords,
        setNearmapcoords
      );
    }
  }, [polygonList, parcelStyle]);

  useEffect(() => {
    const elem = document.getElementById("popup-cont");
    setHoverElem(elem);
    initializeMarker(
      map,
      center,
      hex2rgba,
      setHoverData,
      showPopup,
      markerRef,
      setHoverMapKey,
      setSelectedHoverFeature,
      elem,
      true
    );
  }, [center]);

  useEffect(() => {
    latLongToCoordinates(coordinates, setPolygonList);
  }, [coordinates]);

  useEffect(() => {
    if (map)
      map.getLayers().forEach(function (layer) {
        if (layer && layer.get("title") == "NearmapImage") {
          if (showNearmapImage) layer.setVisible(true);
          else layer.setVisible(false);
        }
      });
  }, [showNearmapImage]);

  const coordinatesToLatLong = () => {
    let parcelData = [];
    for (let i = 0; i < polygonList.length; i++) {
      const coordinate = [];
      let coords = polygonList[i].data;
      for (let j = 0; j < coords.length; j++) {
        const singlePolygon = coords[j];
        const singlePolygonData = [];
        for (let k = 0; k < singlePolygon.length; k++) {
          let lonLat = toLonLat(singlePolygon[k]);
          singlePolygonData.push(lonLat);
        }
        coordinate.push(singlePolygonData);
      }
      parcelData.push(coordinate);
    }

    return { geometry: { type: "MultiPolygon", coordinates: parcelData } };
  };

  const handleCancelDelete = () => {
    // Hide the confirmation dialog
    setshowInprogress(false);
    navigate("/dashboard");
  };

  const placeOrder = () => {
    if (uploadedFileUrl) {
      const data = { attachments: [uploadedFileUrl] };
      apiTokenQueries("PUT", `order/prop-id/${id}`, data).then((res) => {
        if (SUCCESS_STATUS_CODE.includes(res.status)) {
          console.log("uploaded");
        } else if (FAILURE_STATUS_CODE.includes(res.status)) {
          console.log("error");
        }
      });
    }

    const latLng = coordinatesToLatLong();
    const idList = [];
    for (const item of featureList) {
      idList.push(item.id);
    }

    const data = {
      group_feature: featureSelected.id,
      feature_ids: idList,
      parcel_data: latLng,
    };
    if (orderNotes) {
      data["order_note"] = orderNotes;
    }
    if (
      nearmapImageDate !== "satellite" &&
      nearmapImageDate !== undefined &&
      nearmapImageDate !== null
    ) {
      data["imagery"] = nearmapImageDate;
    }
    let apiUrlPlace = `order/prop-id/${id}/place`;
    if (commonAreaPlace) {
      apiUrlPlace =
        apiUrlPlace +
        `?ishoa=${commonAreaPlace}&isCurb=${curbStripPlace}&isfrontYard=${frontYardPlace}`;
    }
    apiTokenQueries("POST", apiUrlPlace, data).then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        setStatus("in_progress");
        setOrderConfirm(false);
        setshowInprogress(true);
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
      }
    });
  };

  const handleCopyLink = () => {
    // Logic to generate and copy the share link
    const shareLink = `https://app.mapnexa.com/share/order/${id}`;

    navigator.clipboard.writeText(shareLink);

    toast.success("Link copied");
  };

  const handleGenerateReport = () => {
    setShowGeneratingReport(true);
    toast.success("Generating Report. Please wait for a while", {
      duration: 5000,
    });

    // Create a unique identifier for this report generation
    const reportId = Date.now();

    // Start the download immediately
    const link = document.createElement('a');
    link.href = `${API_URL}order/prop-id/${id}/view/${viewId}/layers?download=pdf&full_name=${encodeURIComponent(
      fullName
    )}&email=${encodeURIComponent(
      email
    )}&contact_number=${encodeURIComponent(contactNumber)}&report_id=${reportId}`;
    
    link.click();

    // Hide the GeneratingReport element after a delay
    setTimeout(() => {
      setShowGeneratingReport(false);
    }, 18000); // Adjust this delay as needed (e.g., 40.5 seconds)
  };

  const hex2rgba = (hex, alpha) => {
    if (hex) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    }
  };

  useEffect(() => {
    handleOrderVisiblity(map, uploadedLayer, orderActive, setUploadedLayer);
  }, [orderActive]);

  useEffect(() => {
    if (hoverParcelElement && hoverParcelId) {
      hoverOnParcel(map, hoverParcelElement, hoverParcelId, parcelEdit);
    }
  }, [hoverParcelId, hoverParcelElement]);

  useEffect(() => {
    if (hoverOrderFeatureElement && Number(hoverOrderFeatureId[0])) {
      hoverOnOrderFeature(
        map,
        hex2rgba,
        hoverOrderFeatureElement,
        hoverOrderFeatureId,
        setHoverData,
        showPopup,
        editInteraction
      );
    }
  }, [hoverOrderFeatureId]);

  useEffect(() => {
    if (!nearmapImageDate) {
      if (status === STATUS.DRAFT) {
        if (nearmapcoords.length && nearmapcoords[0] != Infinity) {
          getNearmapImage(id, nearmapcoords, setNearmapSurveyData);
        }
      }
    }
  }, [nearmapcoords]);

  useEffect(() => {
    let totalArea = 0;
    for (let i = 0; i < polygonList.length; i++) {
      totalArea += parseFloat(polygonList[i].measurement);
    }
    // totalArea = totalArea * 43560
    setParcelArea(totalArea.toFixed(2));
  }, [polygonList]);

  useEffect(() => {
    if (id) {
      apiTokenQueries("GET", `order/feature`).then((res) => {
        if (SUCCESS_STATUS_CODE.includes(res.status)) {
          const featureData = res.data.data;
          console.log(featureData, "featureData ");
          // setFeatureList(featureData)
          setTotalFeatureList(featureData);
          // Log names of features with feature_type "point"
          const pointFeatures = featureData.filter(
            (feature) => feature.feature_type === "point"
          );
          console.log(
            "Point features: name",
            pointFeatures.map((feature) => feature.name)
          );
        } else if (FAILURE_STATUS_CODE.includes(res.status)) {
          toast.error(res.data.errorDesc);
        }
      });

      apiTokenQueries("GET", `order/feature/group`).then((res) => {
        if (SUCCESS_STATUS_CODE.includes(res.status)) {
          const featureData = res.data.data;
          setFeatureGroup(featureData);
        } else if (FAILURE_STATUS_CODE.includes(res.status)) {
          toast.error(res.data.errorDesc);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (status === "in_progress" || status === "measurement_completed")
      apiTokenQueries("GET", `order/prop-id/${id}/feature`).then((res) => {
        if (SUCCESS_STATUS_CODE.includes(res.status)) {
          const featureData = res.data.data;
          setFeatureList(featureData);
        } else if (FAILURE_STATUS_CODE.includes(res.status)) {
          toast.error(res.data.errorDesc);
        }
      });

    if (status === "measurement_completed")
      apiTokenQueries("GET", `order/prop-id/${id}/view`).then((res) => {
        if (SUCCESS_STATUS_CODE.includes(res.status)) {
          const viewData = res.data.data;

          if (viewData.length) {
            const viewId = viewData[0].id;
            setViewId(viewId);
            //   apiTokenQueries("GET", `order/prop-id/${id}/view/${viewId}/layer/info`).then((res)=>{
            //     if(SUCCESS_STATUS_CODE.includes(res.status)){
            //         const layersData = res.data.data
            //         setMappedFeatureList(layersData)

            //     }
            //     else if(FAILURE_STATUS_CODE.includes(res.status)){
            //     toast.error(res.data.errorDesc)
            //     }
            // })
          }
        } else if (FAILURE_STATUS_CODE.includes(res.status)) {
          toast.error(res.data.errorDesc);
        }
      });
  }, [status]);

  useEffect(() => {
    if (viewId !== null) {
      getLayersData(id, viewId, setLayersData, setUploadedLayer);
    }
  }, [viewId]);

  useEffect(() => {
    setLayersCount(layersData.length);
    layersData.forEach((layer) => {
      const layerId = layer.id;
      getLayerData(
        id,
        viewId,
        layerId,
        stopLoader,
        setDownloadData,
        setUploadedLayer
      );
    });
  }, [layersData]);

  useEffect(() => {
    if (map) {
      setOlMap(map);
      if (status === STATUS.DIY || status === STATUS.MEASUREMENT_COMPLETED) {
        renderOrderLayer(map, hex2rgba, orderRef, uploadedLayer);
      }
      stopLoader();
    }
  }, [uploadedLayer, layersData]);

  useEffect(() => {
    if (map) {
      // Remove all existing Tile Imagery layers
      map.getLayers().forEach((layer) => {
        if (layer && layer.get("title") === "Tile Imagery") {
          map.removeLayer(layer);
        }
      });

      if (status === STATUS.DRAFT) {
        if (nearmapImageDate === "SATELLITE") {
          handleLayerTypeSatellite(map);
        } else if (nearmapcoords.length && nearmapcoords[0] != Infinity) {
          // Fetch Nearmap image data
          getNearmapImage(id, token, nearmapcoords, setNearmapSurveyData);
        }
      } else {
        // For other statuses, use Backblaze imagery
        if (nearmapcoords.length) {
          nearmapcoords.forEach((item) => {
            setBackblazeImage(map, imageDate, item, tileGridLyr);
          });
        }
      }
    }
  }, [map, nearmapcoords, nearmapImageDate, status, imageDate]);

  // Add this new useEffect to handle setting Nearmap imagery once we have the survey data
  useEffect(() => {
    if (map && status === STATUS.DRAFT && nearmapSurveyData.length > 0) {
      setNearmap(map, nearmapImageDate, nearmapcoords, tileGridLyr,token);
    }
  }, [map, status, nearmapSurveyData, nearmapImageDate, nearmapcoords]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleInfoPopup = () => {
    setShowInfoPopup(!showInfoPopup);
  };
  const handleNextDraft = () => {
    apiTokenQueries("GET", "order/user?status=draft&p=1&psz=1").then((res) => {
      if (SUCCESS_STATUS_CODE.includes(res.status)) {
        const orderData = res.data.data;
        if (orderData.length > 0) {
          const nextDraftId = orderData[0].order_id;
          navigate(`/order/${nextDraftId}`, { replace: true });
          window.location.reload();
        } else {
          toast.error("No more draft orders available");
          navigate("/dashboard", { replace: true });
          window.location.reload();
        }
      } else if (FAILURE_STATUS_CODE.includes(res.status)) {
        toast.error(res.data.errorDesc);
        navigate("/dashboard", { replace: true });
        window.location.reload();
      }
    });
  };

  return (
    <div ref={contentRef} className="ol-order-id-page">
      <div className="PropertyOptionOl">
        <div className="TagDraftContainer">{address}</div>
        {status === "draft" ? (
          <div className="ToolsContainer">
            <div
              className="ToolEditor"
              onClick={() => {
                setShowDone(true);
                drawPolygon(
                  map,
                  parcelRef,
                  polygonList,
                  setZoomLayer,
                  setParcelEdit,
                  setPolygonList,
                  setParcelStyle
                );
              }}
            >
              <img src={DrawPolygon} alt="Draw" />
              Draw
            </div>
            <div
              className="edit-parcel-buttons ToolEditor"
              onClick={editPolygon}
            >
              <img src={EditPolygon} alt="Edit" />
              Edit
            </div>
            <div
              className="ToolEditor"
              onClick={() => {
                document.body.classList.remove("delete-cursor");
                setShowDone(true);
                cutPolygon(
                  map,
                  parcelRef,
                  polygonList,
                  setZoomLayer,
                  setParcelEdit,
                  setPolygonList,
                  setParcelStyle
                );
              }}
            >
              <img src={CutPolygon} alt="Cut" />
              Cut
            </div>
            <div
              className="delete-parcel-buttons ToolEditor"
              onClick={deletePolygon}
            >
              <img src={DeletPolygon} alt="Delete" />
              Delete
            </div>
          </div>
        ) : status === "measurement_completed" ? (
          <>
            {windowWidth > 1000 ? (
              <div className="share-container">
                <div className="report-button">
                  <a
                    onClick={handleGenerateReport}
                    className="generate-report-link"
                    href="javascript:void(0);"
                  >
                    Generate Report
                  </a>
                </div>
                <div className="copy-link-button" onClick={handleCopyLink}>
                  Share
                </div>
                <div className="ExportContainer">
                  <div className="export-dropdown">
                    <div
                      className="export-label"
                      onMouseEnter={() => setShowExportOptions(true)}
                      onMouseLeave={() => setShowExportOptions(false)}
                    >
                      Export{" "}
                      {showExportOptions && (
                        <div className="export-options">
                          <div>
                            <a
                              href={`${API_URL}order/prop-id/${id}/view/${viewId}/layers?download=xlsx`}
                            >
                              Excel
                            </a>
                          </div>
                          <div>
                            <a
                              href={`${API_URL}order/prop-id/${id}/view/${viewId}/layers?download=zip`}
                            >
                              GeoJSON
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="info-container">
                <button className="info-button" onClick={toggleInfoPopup}>
                  <FileDownloadOutlinedIcon style={{ color: "white" }} />
                </button>
              </div>
            )}
          </>
        ) : null}
      </div>

      {showInprogress && (
        <div>
          <div className="overlay"></div>
          <div className="delete-confirmation">
            <p>Take a coffee break. Our team is working on the measurements.</p>
            <div>
              <img src={Hourglass} alt="Order loader" />
            </div>
            <div className="button-container">
              <button className="sure-button" onClick={handleCancelDelete}>
                Okay
              </button>
              <button className="next-draft-button" onClick={handleNextDraft}>
                Next Draft
              </button>
            </div>
          </div>
        </div>
      )}
      {orderConfirm && (
        <div>
          <div className="overlay"></div>
          <div className="order-confirmation">
            <p>Order Note</p>
            <TextField
              fullWidth
              label="Add order note here"
              id="fullWidth"
              style={{ backgroundColor: "white" }}
              value={orderNotes}
              onChange={(event) => {
                setOrderNotes(event.target.value);
              }}
            />

            <label htmlFor="fileInput" className="custom-file-upload">
              Upload Document
            </label>
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />

            {uploadedFile && (
              <div style={{ cursor: "pointer", fontSize: ".9rem" }}>
                <p>Uploaded File:</p>
                <div style={{ cursor: "pointer", marginTop: "-10%" }}>
                  {uploadedFile && (
                    <>
                      <span>{uploadedFile.name}</span>
                      <button
                        onClick={handleDeleteFile}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#3ba364",
                          color: "white",
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="confirmCancelDiv">
              <button onClick={placeOrder}>Confirm</button>
              <button
                onClick={() => {
                  setOrderConfirm(false);
                }}
              >
                Cancel
              </button>
            </div>
            {uploading && <div className="loader">Uploading...</div>}
          </div>
        </div>
      )}

      {showDone && (
        <div className="done-operation">
          <Button
            onClick={() => {
              removeAllInteraction(map);
              document.body.classList.remove("cut-cursor");
              document.body.classList.remove("delete-cursor");
              setParcelStyle([
                new Style({
                  stroke: new Stroke({
                    color: "red",
                    width: 2,
                  }),
                  fill: new Fill({
                    color: "rgb(255, 255, 255,0.2)",
                  }),
                }),
              ]);
              setShowDone(false);
            }}
            className="stop-operation"
            variant="contained"
            fullWidth
          >
            Done
          </Button>
        </div>
      )}

      {showInfoPopup && (
        <div className="info-popup-overlay" onClick={toggleInfoPopup}>
          <div className="info-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={toggleInfoPopup}>
              <CloseIcon />
            </button>
            <div className="info-popup-content">
              <div className="info-popup-item">
                <a
                  onClick={handleGenerateReport}
                  className="generate-report-link"
                  href="javascript:void(0);"
                >
                  <PictureAsPdfIcon className="info-popup-icon" />
                  <span>Generate Report</span>
                </a>
              </div>
              <div className="info-popup-item" onClick={handleCopyLink}>
                <ShareTwoToneIcon className="info-popup-icon" />
                <span>Share</span>
              </div>
              <div className="info-popup-item">
                <a
                  href={`${API_URL}order/prop-id/${id}/view/${viewId}/layers?download=xlsx`}
                >
                  <TextSnippetIcon className="info-popup-icon" />
                  <span>Export Excel</span>
                </a>
              </div>
              <div className="info-popup-item">
                <a
                  href={`${API_URL}order/prop-id/${id}/view/${viewId}/layers?download=zip`}
                >
                  <DataObjectIcon className="info-popup-icon" />
                  <span>Export GeoJSON</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="map-edit-container">
        {hasAccess && (
          <>
            <div className={`map-left-bar ${showToggleOn ? "hidden" : ""}`}>
              <div className="ConfirmPropertyContainer">
                <div className="PropertyFeature">
                  <div className="PropertFeatureList">
                    <div className="PropertyFeatureList">
                      {status === "draft" ? (
                        <div>
                          <DropDown
                            featureGroup={featureGroup}
                            setFeatureList={setFeatureList}
                            setFeatureSelected={setFeatureSelected}
                          />
                        </div>
                      ) : null}

                      {status !== "measurement_completed" ? (
                        <MultiCheckbox
                          options={featureList}
                          setFeatureCount={setFeatureCount}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {status !== "measurement_completed" ? (
                <SubmitOrder
                  parcelArea={parcelArea}
                  featureList={featureList}
                  featureSelected={featureSelected}
                  polygonList={polygonList}
                  id={id}
                  orderStatus={status}
                  setOrderStatus={setStatus}
                  nearmapImageDate={nearmapImageDate}
                  setOrderConfirm={setOrderConfirm}
                  commonAreaPlace={commonAreaPlace}
                  curbStripPlace={curbStripPlace}
                  frontYardPlace={frontYardPlace}
                />
              ) : (
                <div className="scrollable-accordion">
                  <GeoJSONAccordion
                    order_id={id}
                    geojsonData={uploadedLayer}
                    setUploadedLayer={setUploadedLayer}
                    uploadedLayer={uploadedLayer}
                    map={map}
                    hex2rgba={hex2rgba}
                  />
                </div>
              )}
              {status === "draft" ? (
                <div>
                  <CustomizeFeature
                    options={featureList}
                    setFeatureCount={setFeatureCount}
                    totalFeatureList={totalFeatureList}
                    setFeatureList={setFeatureList}
                    setcommonAreaPlace={setcommonAreaPlace}
                    commonAreaPlace={commonAreaPlace}
                    curbStripPlace={curbStripPlace}
                    setcurbStripPlace={setcurbStripPlace}
                    frontYardPlace={frontYardPlace}
                    setfrontYardPlace={setfrontYardPlace}
                  />
                </div>
              ) : null}
              <div id="hover-popup" className="popup"></div>
            </div>
            <div className="toggle-bar-olid">
              <div className="switch-btn-olid-toggle" onClick={toggleShowBtn}>
                {showToggleOn ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </div>
            </div>
          </>
        )}
        <div ref={mapElement} id="map-cont" className="main-ol-container">
          {status === STATUS.DRAFT &&
          nearmapSurveyData &&
          polygonList.length ? (
            <div className="image-date-cont">
              <ImageryDropdown
                nearmapSurveyData={nearmapSurveyData}
                setNearmapImageDate={setNearmapImageDate}
              />
            </div>
          ) : null}
          <div id="popup-cont" className="popup"></div>
          <div className="map-buttons">
            <div className="map-type-buttons">
              <button onClick={() => handleLayerTypeMap(map)}>Map</button>
              <button onClick={() => handleLayerTypeSatellite(map)}>
                Satellite
              </button>
            </div>
            {
              <div className="map-zoom-buttons">
                <button onClick={() => handleZoomIn(map)}>+</button>
                <button onClick={() => handleZoomOut(map)}>-</button>
              </div>
            }
          </div>
        </div>
      </div>

      {}
    </div>
  );
};

export default OpenLayersIdPage;
