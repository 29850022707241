import React, { useContext, useState, createContext, useRef } from "react";

const FeedbackContext = createContext();

export function FeedbackContextProvider({ children }) {
  const feedbackInnerRef = useRef();
  const feedbackOutterRef = useRef();

  return (
    <FeedbackContext.Provider
      value={{
        feedbackInnerRef,
        feedbackOutterRef,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
}

export function useFeedbackContext() {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
