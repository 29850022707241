import axios from "axios";
import AuthToken from "./AuthToken";
import { ORDER_URL } from "../helper/ApiUrl";
import React, { useContext, useState, useEffect, createContext } from "react";

const OrderContext = createContext();

export function OrderContextProvider({ children }) {
  const token = AuthToken();
  const [orderId, setOrderId] = useState(null);
  const [orderData, setOrderData] = useState();

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${ORDER_URL}${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          setOrderData(response.data);
        })
        .catch((error) => {
          console.error(error.message);
        });
    };
    if (orderId !== null) {
      fetchData();
    }
  }, [orderId]);
  return (
    <OrderContext.Provider
      value={{
        orderData,
        setOrderId,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export function useOrderContext() {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
