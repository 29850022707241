import React, { useEffect, useState, useMemo } from "react";
import "./ImageryDropdown.css";
import { Switch } from "@mui/material";

export default function ImageryDropdown({
  nearmapSurveyData,
  setNearmapImageDate,
}) {
  const [date, setDate] = useState("satellite"); // Default to "satellite"

  // Memoize the sorted survey data
  const sortedSurveyData = useMemo(() => {
    return [...nearmapSurveyData].sort((a, b) => 
      new Date(b.captureDate) - new Date(a.captureDate)
    );
  }, [nearmapSurveyData]);

  useEffect(() => {
    if (sortedSurveyData.length) {
      setDate("satellite");
      setNearmapImageDate("satellite");
    }
  }, [sortedSurveyData, setNearmapImageDate]);

  const handleChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    setNearmapImageDate(selectedDate);
  };

  const handleSwitchChange = (e) => {
    console.log("value", e.target.checked);
    const selectedDate = e.target.checked ? sortedSurveyData[0].captureDate : "satellite";
    setDate(selectedDate);
    setNearmapImageDate(selectedDate);
  };

  return (
    <div className="imagery-dropdown-cont">
      <div id="select-imagery-head">Select Imagery</div>
      <select
        onChange={handleChange}
        className="imagery-dropdown-select"
        name="nearmap-image-date"
        id="nearmap-image-date"
        value={date}
      >
        <option value="satellite">Satellite</option>
        {sortedSurveyData.map((item) => (
          <option value={item.captureDate} key={item.id}>
            {item.captureDate}
          </option>
        ))}
      </select>
      <div className="hd-switch-cont">
        HD
        <Switch
          className="hd-switch"
          aria-label="Hd Imagery"
          checked={date !== "satellite"}
          onChange={handleSwitchChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
    </div>
  );
}
